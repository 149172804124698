import "./App.css";
import React from "react";
// import { Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Blogs from "./Blogs";
import Changelog from "./Changelog";
import PrivacyPolicy from "./PrivacyPolicy";
import NavBar from "./CustomNavBar";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/mobile/changelog",
    element: <Changelog />,
  }
,
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  }
]);

function App() {
  return (
    <>
    
    {/* <Navbar expand="lg" className="bg-body-tertiary">
     
    <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/blogs">Blogs</Nav.Link>
           
            
            
          </Nav>
     
    
    </Navbar> */}


    <div className="App">  
   
      
      <RouterProvider router={router} />
    </div>
    </>
  );
}

export default App;
