
import React from "react";
import  "./PrivacyPolicy.css";


function PrivacyPolicy() {
  return (
    <div className="privacy-main" id="privacyParent">    

    <div>

    
     <h1>Privacy Policy for Snacko</h1>

<p>Last Updated on 22 February, 2024</p>
<br />
     <h4>
      
     This privacy notice for Snacko It ("Company," "we," "us," or "our"This privacy notice for Snacko It ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:

<br />
<br />
<ul>
<li>

Download and use our mobile application (Snacko), or any other application of ours that links to this privacy notice</li>
<li>Engage with us in other related ways, including any sales, marketing, or events</li>
    
</ul>
     </h4>



</div>
<div>
<br />

<br />

<div id="summaryKeyPoints">


<u><h4>Summary of key points</h4></u><br />
<ol>
<li>
Do we process any sensitive personal information?<br />No, We do not process sensitive personal information.
</li>
<br />
<li>Do we receive any information from third parties?<br />No, We do not receive any information from third parties.
</li>
<br />
<li>
How do we process your information?<br />We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.
</li>

<br />
<li>
In what situations and with which parties do we share personal information?<br />We do not share information in any situations and with specific third parties.
</li>

<br />
<li>
How do you exercise your rights?<br />The easiest way to exercise your rights is by contacting us at ankit@snacko.health. We will consider and act upon any request in accordance with applicable data protection laws.

</li>

<br />
<li>
How do we keep your information safe?<br />We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. We use services provided by<b> AWS and use TLS 2.0 encryption</b> for data in transit.
</li>
   
</ol>
</div>

<br />
<br />

<div id="privacyQuestions">
  <u>
  <h4>
Table of Contents
    </h4>
  </u> <br />
    <ol>
<li><a href="#what_info_we_collect">WHAT INFORMATION DO WE COLLECT?</a></li>
<li> <a href="#how_do_we_process_your_information">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
<li> <a href="#what_legal_bases_do_we_rely_on_to_process_your_personal_information">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></li>
<li> <a href="#when_and_with_whom_do_we_share_your_personal_information">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
<li><a href="#how_do_we_handle_your_social_logins"> HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>



<li> <a href="#how_long_do_we_keep_your_information">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
<li> <a href="#how_do_we_keep_your_information">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
<li> 
<a href="#do_we_collect_information_from_minors">DO WE COLLECT INFORMATION FROM MINORS?</a></li>


<li> 
<a href="#what_are_your_privacy_rights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>

<li>
<a href="#controls_for_do_not_track_features">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>

<li> 
<a href="#do_california_residents_have_specific_privacy_rights">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>

<li> 
<a href="#do_virginia_residents_have_specific_privacy_rights">DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>


<li>
<a href="#do_we_make_updates_to_this_notice">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>

<li>
<a href="#how_can_you_review_update_or_delete_the_data_we_collect_from_you">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>

    </ol>
</div>

<div id="privacyAnswers">


<div id="what_info_we_collect">
<b>WHAT INFORMATION DO WE COLLECT?
</b><br />
Personal information you disclose to us

In Short: We collect personal information that you provide to us.

We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:

names
email addresses
usernames
birthdate
height & weight

Sensitive Information. We do not process sensitive information.


Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.

All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.

Information automatically collected

In Short: We do not collect any personal information automatically. Only Non identifiable statistical data.

The information we collect includes:
Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files.
</div>
<br /><br />
<div id="how_do_we_process_your_information">

<b>HOW DO WE PROCESS YOUR INFORMATION?</b><br />    

In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.

We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.


To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.


</div>
<br /><br />

<div id="what_legal_bases_do_we_rely_on_to_process_your_personal_information">
<b>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</b><br />
In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
<br />
<b>If you are located in the EU or UK, this section applies to you.
</b>
The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.
Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
<br />
<b>If you are located in Canada, this section applies to you.</b>

We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.

In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
For investigations and fraud detection and prevention
For business transactions provided certain conditions are met
If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
For identifying injured, ill, or deceased persons and communicating with next of kin
If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
If the collection is solely for journalistic, artistic, or literary purposes
If the information is publicly available and is specified by the regulations

</div>

<br /><br />

<div id="when_and_with_whom_do_we_share_your_personal_information">
<b>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</b><br />
<br />
In Short: We may share information in specific situations described in this section and/or with the following third parties.

We may need to share your personal information in the following situations:<br />
<b>Business Transfers</b>. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.


</div>


<br /><br />
<div id="how_do_we_handle_your_social_logins">
<b>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</b><br />
In Short: As of now, we do not have social logins. We do not have the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins).
<br />



</div>
<br /><br />

<div id="how_long_do_we_keep_your_information"> 
<b>HOW LONG DO WE KEEP YOUR INFORMATION?</b><br />
In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.<br />



We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.

When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
</div>
<br /><br />

<div id="how_do_we_keep_your_information">
<b>HOW DO WE KEEP YOUR INFORMATION SAFE?</b><br />
In Short: We aim to protect your personal information through a system of organizational and technical security measures.

We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.

</div>
<br /><br />

<div id="do_we_collect_information_from_minors">
<b>DO WE COLLECT INFORMATION FROM MINORS?</b><br />
In Short: We do not knowingly collect data from or market to children under 18 years of age.
<br />

We <b>do not</b> knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at ankit@snacko.health.
</div>
<br /><br />


<div id="what_are_your_privacy_rights">
<b>WHAT ARE YOUR PRIVACY RIGHTS?</b><br />
In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.<br />

In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.

We will consider and act upon any request in accordance with applicable data protection laws.
 
If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.

If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.

Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or updating your preferences.

However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
<br /><br />
<b>Account Information
</b>
If you would at any time like to review or change the information in your account or terminate your account, you can:
Log in to your account settings and update your user account.
Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.

If you have questions or comments about your privacy rights, you may email us at ankit@snacko.health.
</div>
<br /><br />



<div id="controls_for_do_not_track_features">
<b>CONTROLS FOR DO-NOT-TRACK FEATURES</b><br />
As of now, we do not engage in tracking activities. We do not track your online browsing activities on our Services and across third-party websites or online services.
<br />
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

</div>


<br /><br />
<div id="do_california_residents_have_specific_privacy_rights">
<b>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b><br />
In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.

California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.

If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).

CCPA Privacy Notice

The California Code of Regulations defines a "resident" as:
<br />
every individual who is in the State of California for other than a temporary or transitory purpose and<br />
every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose

All other individuals are defined as "non-residents."

If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.

What categories of personal information do we collect?

We have collected the following categories of personal information in the past twelve (12) months:


<table>
  <tbody>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Category</strong>
            </span>
          </span>
        </span>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderTop: "1px solid black",
          borderRight: "1px solid black"
        }}
      >
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Examples</strong>
            </span>
          </span>
        </span>
      </td>
      <td
        style={{
          width: "14.9084%",
          borderRight: "1px solid black",
          borderTop: "1px solid black",
          textAlign: "center"
        }}
      >
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Collected</strong>
            </span>
          </span>
        </span>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">A. Identifiers</span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderTop: "1px solid black",
          borderRight: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          verticalAlign: "middle",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                YES
                <bdt className="else-block" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                B. Personal information categories listed in the California
                Customer Records statute
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Name, contact information, education, employment, employment
                history, and financial information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                C. Protected classification characteristics under California or
                federal law
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Gender and date of birth
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                Yes
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                D. Commercial information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Transaction information, purchase history, financial details,
                and payment information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                E. Biometric information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Fingerprints and voiceprints
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                F. Internet or other similar network activity
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Browsing history, search history, online
                <bdt className="block-component" />
                behavior
                <bdt className="statement-end-if-in-editor" />, interest data,
                and interactions with our and other websites, applications,
                systems, and advertisements
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">G. Geolocation data</span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">Device location</span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                H. Audio, electronic, visual, thermal, olfactory, or similar
                information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Images and audio, video or call recordings created in connection
                with our business activities
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          width: "33.8274%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                I. Professional or employment-related information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "51.4385%",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Business contact details in order to provide you our Services at
                a business level or job title, work history, and professional
                qualifications if you apply for a job with us
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          width: "14.9084%",
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderTop: "1px solid black",
          width: "33.8274%"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                J. Education Information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderTop: "1px solid black",
          width: "51.4385%"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Student records and directory information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          textAlign: "center",
          borderRight: "1px solid black",
          borderTop: "1px solid black",
          width: "14.9084%"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          borderWidth: 1,
          borderColor: "black",
          borderStyle: "solid",
          width: "33.8274%"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                K. Inferences drawn from other personal information
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          borderBottom: "1px solid black",
          borderTop: "1px solid black",
          borderRight: "1px solid black",
          width: "51.4385%"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual’s preferences and characteristics
              </span>
            </span>
          </span>
        </div>
      </td>
      <td
        style={{
          textAlign: "center",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          borderTop: "1px solid black",
          width: "14.9084%"
        }}
      >
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                NO
                <bdt className="statement-end-if-in-editor">
                  <span data-custom-class="body_text" />
                </bdt>
              </span>
            </span>
          </span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        style={{
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <span data-custom-class="body_text">
          L. Sensitive Personal Information
        </span>
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          lineHeight: "1.5"
        }}
      >
        <bdt className="block-component">
          <span data-custom-class="body_text" />
        </bdt>
      </td>
      <td
        data-custom-class="body_text"
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          textAlign: "center",
          lineHeight: "1.5"
        }}
      >
        <bdt className="block-component">
          <span data-custom-class="body_text" />
        </bdt>
        NO
        <bdt className="statement-end-if-in-editor">
          <span data-custom-class="body_text" />
        </bdt>
      </td>
    </tr>
  </tbody>
</table>


</div>
<br /><br />

<div id="do_virginia_residents_have_specific_privacy_rights">
<b>DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b><br />
In Short: Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.

Virginia CDPA Privacy Notice

Under the Virginia Consumer Data Protection Act (CDPA):

"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.

"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.

"Sale of personal data" means the exchange of personal data for monetary consideration.

If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.

The information we collect, use, and disclose about you will vary depending on how you interact with Snacko It and our Services. To find out more, please visit the following links:
Personal data we collect
How we use your personal data
When and with whom we share your personal data
Your rights with respect to your personal data
Right to be informed whether or not we are processing your personal data
Right to access your personal data
Right to correct inaccuracies in your personal data
Right to request deletion of your personal data
Right to obtain a copy of the personal data you previously shared with us
Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
Snacko It has not sold any personal data to third parties for business or commercial purposes. Snacko It will not sell personal data in the future belonging to website visitors, users, and other consumers.

Exercise your rights provided under the Virginia CDPA

More information about our data collection and sharing practices can be found in this privacy notice.

You may contact us by email at ankit@snacko.health, by visiting ankit@snacko.health, or by referring to the contact details at the bottom of this document.

If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.

Verification process

We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.

Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.

Right to appeal

If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at ankit@snacko.health. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the Attorney General to submit a complaint.
</div>

<br /><br />

<div id="do_we_make_updates_to_this_notice">
<b>DO WE MAKE UPDATES TO THIS NOTICE?</b><br />


In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.

</div>
<br /><br />
<div id="how_can_you_review_update_or_delete_the_data_we_collect_from_you">
<b>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</b><br />
You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us at :<a href="mailto:iankit@snacko.health" >ankit@snacko.health.</a>
</div>

<br /><br />
<br /><br />

</div>



  
<div>

<h2>Children's Privacy</h2><br />


Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.

If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
</div>
<br /><br />
<div >
    <h2> Personal and Sensitive User Data</h2><br />
    We <b> do not </b> collect or store any personal data apart from weight, height birthdate, name and email.
</div>


<br /><br />



<div >
    <h2> Do Not sell my personal data</h2><br />
    "Sale" means the exchange or transfer of personal and sensitive user data to a third party for monetary consideration.
User-initiated transfer of personal and sensitive user data (for example, when the user is using a feature of the app to transfer a file to a third party, or when the user chooses to use a dedicated purpose research study app), is not regarded as sale.
<br />
<b> However, we do not sell any data to third parties</b>

</div>


<br /><br />


<br /><br /><br /><br />
<br /><br />
    </div>
    </div>
  );
}

export default PrivacyPolicy;
