import React from "react";

import AppStore from './images/app_store.svg'
import PlayStore from './images/play_store.svg'
import ScreenshotBanner from './images/all_ss.svg'
import "./homepage.css";
import NavBar from "./CustomNavBar";

function HomePage() {
  return (
    <>
  
<div className="bg" >
</div>


      <div className="Homepage">
        <div id="title">
          <span id="titleText">
            <center>
              Snacko <br />
            </center>
          </span>
        </div>

        <div id="paraText">
          <center>
            Get Realtime Nutritional Information
            <br />
            Anywhere, Anytime, Free
          </center>
        </div>
        <br />
        <center className="testMsg">
            We are doing rounds of testing at the alpha stage<br />Do give us some<br /> <a href="mailto:ankit@snacko.health"><div className="testMsg">
            <u>feedback</u>
            </div> </a>
          
          <br />
          <br />
          <br />



          <a href="https://apps.apple.com/us/app/snacko-it/id6450678993">
          <img src={AppStore} alt="Your SVG" />
          </a>
          <span>    </span>
     
          <a href="https://play.google.com/store/apps/details?id=health.snacko">
          <img src={PlayStore} alt="Your SVG" />
          </a>
   
          </center>
        <div className="btn-homepage">

       
          <p>
         
         
         

        
          </p>
        </div>
    
      </div>
    </>
  );
}

export default HomePage;
